import React, { useEffect, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"
import { Container } from "react-bootstrap"
import NotificationManager from "utils/notifications"
import Breadcrumbs from "components/Breadcrumbs"
import { observer } from "mobx-react-lite"
import DealForm from "components/DealForm"
import { Helmet } from "react-helmet-async"
import DealsService from "services/DealsService"
import Loader from "components/Loader"

const DealEditPage = () => {
  const { uuid } = useParams()
  const location = useLocation()
  const [initial, setInitial] = useState(location.state?.initial || (uuid ? undefined : {}))
  const history = useHistory()
  const [errrosDict, setErrorsDict] = useState()

  useEffect(() => {
    async function fetchData(uuid) {
      return await DealsService.fetchTempDeal(uuid)
    }
    if (uuid) {
      fetchData(uuid).then((data) => setInitial(data))
    }
  }, [uuid])

  const onSubmit = async (data) => {
    try {
      Object.keys(data).forEach((key) => {
        if (data[key] === "") {
          data[key] = null
        }
      })
      const { data: deal } = await DealsService.create(data)
      NotificationManager.success("сделка сохранена")
      history.push(`/deals/${deal.id}/`)
    } catch (error) {
      NotificationManager.warning("Ошибка сохранения формы")
      setErrorsDict(error)
    }
  }

  return (
    <Container fluid>
      <Helmet>
        <title>{`Otzoff.ru | Сделки | Создание новой сделки`}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { to: "/", title: "Главная" },
          { to: "/deals/", title: "Сделки" },
          { to: null, title: `Создание` },
        ]}
      />
      {!initial ? <Loader /> : <DealForm errorsDict={errrosDict} initial={initial} onSubmit={onSubmit} />}
    </Container>
  )
}

export default observer(DealEditPage)
