import React from 'react';
import {Breadcrumb} from "react-bootstrap";
import {Link} from "react-router-dom";

const Breadcrumbs = ({items}) => {
  return (
    <Breadcrumb className="mt-3">
      {items.map(item => (
        !!item.to
        ? <Breadcrumb.Item key={item.title} linkAs={Link} linkProps={{to: item.to}}>{item.title}</Breadcrumb.Item>
        : <Breadcrumb.Item key={item.title} >{item.title}</Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
