import React from 'react'
import Select, {components} from "react-select"
import {Col, Form, FormGroup, Row} from "react-bootstrap"
import {useController} from "react-hook-form"
import styles from "./FormSelect.module.sass"

const CustomControl = ({children, ...props}) => (
  <components.Control {...props} className={styles["custom-control"]}>
    {children}
  </components.Control>
)

const CustomIndicatorsContainer = ({children, ...props}) => (
  <components.IndicatorsContainer {...props} className={styles["custom-indicator-container"]}>
    {children}
  </components.IndicatorsContainer>
)


const FormSelect = ({label, name, options}) => {
  const {field: {onChange, value}, formState: {isSubmitted}, fieldState: {error}} = useController({name})

  return (
    <FormGroup as={Row} className="mb-3" controlId={`${name}_id`}>
      <Form.Label column sm={2}>{label}</Form.Label>
      <Col s={10}>
        <Select
          options={options}
          components={{Control: CustomControl, IndicatorsContainer: CustomIndicatorsContainer}}
          name={name}
          selectOption={onChange}
          value={options.find(option => option.value === value)}
          onChange={option => onChange(option.value)}
          className={isSubmitted && !error ? "is-valid" : isSubmitted && !!error ? "is-invalid" : null}
        />
        <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>
      </Col>
    </FormGroup>
  );
};

export default FormSelect
