import React, { useEffect, useState } from "react"
import { Container } from "react-bootstrap"
import { observer } from "mobx-react-lite"
import Breadcrumbs from "components/Breadcrumbs"
import ClientsTable from "components/ClientsTable"
import ClientsService from "services/ClientsService"
import { Helmet } from "react-helmet-async"

const ClientListPage = observer(() => {
  const [clients, setClients] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      const res = await ClientsService.fetchAll()
      setClients(res)
      setIsLoading(false)
    })()
  }, [])

  return (
    <Container fluid>
      <Helmet>
        <title>{`Otzoff.ru | Клиенты`}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { to: "/", title: "Главная" },
          { to: null, title: "Клиенты" },
        ]}
      />
      <ClientsTable clients={clients} isLoading={isLoading} />
    </Container>
  )
})

export default ClientListPage
