import React, { useContext, useState } from "react"
import { observer } from "mobx-react-lite"
import { Button, Container, Modal, Nav, Navbar } from "react-bootstrap"
import { Link, NavLink } from "react-router-dom"
import { StoreContext } from "store"

const Header = () => {
  const { authStore } = useContext(StoreContext)
  const [isLogoutModalShow, setIsLogoutModalShow] = useState(false)

  const handleLogout = () => {
    setIsLogoutModalShow(false)
    authStore.logout()
  }

  return (
    <Navbar bg="light" expand="lg">
      <Container fluid>
        <Navbar.Brand as={Link} to="/" className="text-secondary">
          otz<span className="text-danger">off</span>.ru
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto">
            <Nav.Link to="/clients/" as={NavLink}>
              Клиенты
            </Nav.Link>
            <Nav.Link to="/deals/" as={NavLink}>
              Сделки
            </Nav.Link>
            <Nav.Link to="/sites/" as={NavLink}>
              Сайты
            </Nav.Link>
          </Nav>
          <div className="text-end">
            {authStore.isAuthenticated ? (
              <>
                <span className="text-success">{authStore.user.username}</span>
                <Button variant="outline-secondary" onClick={() => setIsLogoutModalShow(true)} className="ms-3">
                  logout
                </Button>
              </>
            ) : (
              <Link to="/auth/login/">login</Link>
            )}
          </div>
        </Navbar.Collapse>
        <Modal show={isLogoutModalShow} onHide={() => setIsLogoutModalShow(false)}>
          <Modal.Header>Logout</Modal.Header>
          <Modal.Body>
            <p>Хотите выйти?</p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setIsLogoutModalShow(false)}>
              Отмена
            </Button>
            <Button variant="primary" onClick={handleLogout}>
              Выйти
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </Navbar>
  )
}

export default observer(Header)
