import {Col, Form, FormGroup, Row} from "react-bootstrap"
import React from "react"
import {useFormContext} from "react-hook-form"

const FormInput = ({label, name, type}) => {
  const {register, formState: {isSubmitted, errors}} = useFormContext()
  const error = errors[name]

  return (
    <FormGroup as={Row} className="mb-3" controlId={`${name}_id`}>
      <Form.Label column sm={2}>{label}</Form.Label>
      <Col sm={10}>
        <Form.Control {...register(name)} isInvalid={isSubmitted && !!error} isValid={isSubmitted && !error} type={type || "text"}/>
        <Form.Control.Feedback type="invalid">{error?.message}</Form.Control.Feedback>
      </Col>
    </FormGroup>)
}

export default FormInput
