import punycode from "punycode"

const punydecode = (urlString) => {
  try {
    const url = new URL(urlString)
    return `${url.protocol}//${punycode.toUnicode(url.hostname)}${decodeURI(url.pathname)}${url.search}${url.hash}`
  }
  catch {
    return urlString
  }
}

export default punydecode
