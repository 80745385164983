import React from "react"
import { Container } from "react-bootstrap"

const Page404 = () => {
  return (
    <Container fluid>
      <div className="page-title">Страница не найдена</div>
    </Container>
  )
}

export default Page404
