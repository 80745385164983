import React from 'react'
import ReactNotification from "react-notifications-component"
import ReactDOM from 'react-dom'
import './index.sass'
import App from './App'
import {StoreContext, store} from "store";

ReactDOM.render(
  <React.StrictMode>
    <StoreContext.Provider value={store}>
      <ReactNotification />
      <App />
    </StoreContext.Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
