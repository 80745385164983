import {observer} from "mobx-react-lite"
import DataTable from "react-data-table-component"
import {Link} from "react-router-dom"
import Loader from "components/Loader"
import punidecode from "utils/punidecode"

const SitesTable = ({sites, isLoading, ...props}) => {
  let columns = [
    {
      name: "#",
      selector: site => site.id,
      format: site => <Link to={`/sites/${site.id}/`}>{site.id}</Link>,
      sortable: true,
      minWidth: "77px",
      grow: 0
    },
    {
      name: "Название",
      selector: site => site.name,
      format: site => <Link to={`/sites/${site.id}/`}>{site.name}</Link>,
      sortable: true,
    },
    {
      name: "Url",
      selector: site => site.url,
      format: site => <a href={site.url}>{punidecode(site.url)}</a>,
      sortable: true,
    },
    {
      name: "Email",
      selector: site => site.email,
      format: site => site.email ? <a href={`mailto:${site.email}`}>{site.email}</a> : null,
      sortable: true,
    },
    {
      name: "Способ оплаты",
      selector: site => site.payment_type,
      sortable: true,
    },
    {
      name: "Комментарий",
      selector: site => site.comment,
      sortable: true,
    },
  ]
  return <DataTable
    striped
    columns={columns}
    data={sites}
    defaultSortFieldId={1}
    defaultSortAsc={false}
    dense={true}
    progressPending={isLoading}
    progressComponent={Loader()}
  />
}

export default observer(SitesTable)
