import React, { useState } from "react"
import DataTable from "react-data-table-component"
import { formatDate } from "utils/datetime"
import { Link } from "react-router-dom"
import { MdContentCopy, MdDeleteForever } from "react-icons/all"
import { observer } from "mobx-react-lite"
import Loader from "components/Loader"
import DealsService from "services/DealsService"
import { Button, Modal } from "react-bootstrap"
import NotificationManager from "utils/notifications"

const DealTable = ({ deals, isLoading, exclude, withControls = true, onDelete, ...props }) => {
  const [deletingDeal, setDeletingDeal] = useState(null)

  const handleCopy = async (deal) => {
    const uuid = await DealsService.putTempDeal({
      start_date: deal["start_date"],
      end_date: deal["end_date"],
      client: deal["client"]["id"],
      site: deal["site"]["id"],
      url: deal["url"],
      selling_price: deal["selling_price"],
      purchase_price: deal["purchase_price"],
    })
    const newTab = window.open(`/deals/create/${uuid}/`, "_blank", "noopener,noreferrer")
    if (newTab) newTab.opener = null
  }
  const handleDelete = async () => {
    try {
      await DealsService.delete(deletingDeal.id)
      NotificationManager.success(`сделка #${deletingDeal.id} удалена`)
      onDelete()
    } catch (error) {
      NotificationManager.warning(error.message)
    }
    setDeletingDeal(null)
  }

  let columns = [
    {
      name: "#",
      selector: (deal) => deal.id,
      format: (deal) => <Link to={`/deals/${deal.id}/`}>{deal.id}</Link>,
      sortable: true,
      minWidth: "77px",
      grow: 0,
    },
    {
      name: "Клиент",
      selector: (deal) => deal.client.name,
      format: (deal) => <Link to={`/clients/${deal.client.id}/`}>{deal.client.name}</Link>,
      sortable: true,
    },
    {
      name: "Сайт",
      selector: (deal) => deal.site.name,
      format: (deal) => <Link to={`/sites/${deal.site.id}`}>{deal.site.name}</Link>,
      sortable: true,
      grow: "1.5",
    },
    {
      name: "Статус",
      selector: (deal) => deal.status,
      format: (deal) => (
        <span className={deal.status === "оплачено" ? "text-success" : "text-danger"}>{deal.status}</span>
      ),
      sortable: true,
    },
    {
      name: "Цена продажи",
      selector: (deal) => deal.selling_price,
      format: (deal) => deal.selling_price,
      sortable: true,
    },
    {
      name: "Начало",
      selector: (deal) => deal.start_date,
      format: (deal) => (deal.start_date ? formatDate(deal.start_date) : null),
      sortable: true,
    },
    {
      name: "Окончание",
      selector: (deal) => deal.end_date,
      format: (deal) => (deal.end_date ? formatDate(deal.end_date) : null),
      sortable: true,
    },
    {
      name: "Добавлена",
      selector: (deal) => deal.created,
      format: (deal) => formatDate(deal.created),
      sortable: true,
    },
    {
      id: "contols",
      cell: (deal) => (
        <div style={{ fontSize: "1.5em" }}>
          <span role="button" className="text-primary fw-bold" onClick={() => handleCopy(deal)}>
            <MdContentCopy />
          </span>
          <span role="button" className="text-danger fw-bold ms-4" onClick={() => setDeletingDeal(deal)}>
            <MdDeleteForever />
          </span>
        </div>
      ),
    },
  ]

  if (exclude) {
    columns = columns.filter((field) => !exclude.includes(field.title))
  }
  if (!withControls) {
    columns = columns.filter((field) => field.id !== "contols")
  }

  return (
    <>
      <DataTable
        responsive={true}
        columns={columns}
        data={deals}
        defaultSortFieldId={1}
        defaultSortAsc={false}
        dense={true}
        progressPending={isLoading}
        progressComponent={Loader()}
        paginationRowsPerPageOptions={[10, 50, 100, 150]}
        paginationComponentOptions={{ selectAllRowsItem: true }}
        {...props}
      />
      <Modal onHide={() => setDeletingDeal(null)} show={!!deletingDeal}>
        <Modal.Body>
          Удалить сделку #{deletingDeal?.id} ({deletingDeal?.client.name} - {deletingDeal?.site.name})
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setDeletingDeal(null)}>
            Отмена
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default observer(DealTable)
