export const SeHighlight = ({ children }) => {
  if (children.toLowerCase().startsWith("яндекс")) {
    return (
      <>
        <span className="text-red">Я</span>
        {children.slice(1)}
      </>
    )
  } else if (children.toLowerCase().startsWith("google")) {
    return (
      <>
        <span className="text-blue">G</span>
        {children.slice(1)}
      </>
    )
  } else {
    return children
  }
}
