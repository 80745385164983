import React, { useEffect, useState } from "react"
import SerpsService from "services/SerpsService"
import Loader from "components/Loader"
import { Table } from "react-bootstrap"
import ResultRow from "components/serp/ResultRow"
import punydecode from "utils/punidecode"

const Serp = ({ serpId }) => {
  const [serp, setSerp] = useState(null)
  const [selectedResultId, setSelectedResultId] = useState(null)

  useEffect(() => {
    const fetch = async () => await SerpsService.fetchById(serpId)
    fetch().then((data) => setSerp(data))
  }, [serpId])

  if (!serp) return <Loader />

  return (
    <>
      <Table responsive size="sm">
        <colgroup>
          <col width="20px" />
          <col />
        </colgroup>
        <tbody>
          {serp.results.map((result) => (
            <ResultRow
              key={result["id"]}
              result={result}
              resultUrl={punydecode(result.url).replace(/^https?:\/\//, "")}
              isSelected={selectedResultId === result["id"]}
              handleClick={(resultId) => setSelectedResultId(resultId === selectedResultId ? null : resultId)}
            />
          ))}
        </tbody>
      </Table>
    </>
  )
}

export default Serp
