import {useEffect, useState} from "react"
import SitesService from "services/SitesService"

function useSites(initial=null) {
  const [clients, setSites] = useState(initial);

  useEffect(() => {
    async function loadSites() {
      return await SitesService.fetchAll()
    }

    loadSites().then(data => setSites(data))
  }, []);
  return clients
}


export default useSites;
