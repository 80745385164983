import styles from "components/serp/Serp.module.sass"
import punydecode from "utils/punidecode"
import { formatDate } from "utils/datetime"
import React from "react"
import DealsService from "services/DealsService"

const ResultRow = ({ isSelected, result, handleClick }) => {
  const resultUrl = punydecode(result.url).replace(/^https?:\/\//, "")
  const actualDeal = result["actual_deal"]
  const mostRecentDeal = actualDeal ? actualDeal : result["related_deals"][0]

  const handleCopy = async () => {
    const uuid = await DealsService.putTempDeal({
      start_date: mostRecentDeal["start_date"],
      end_date: mostRecentDeal["end_date"],
      client: mostRecentDeal["client"],
      site: mostRecentDeal["site"],
      url: mostRecentDeal["url"],
      selling_price: mostRecentDeal["selling_price"],
      purchase_price: mostRecentDeal["purchase_price"],
    })
    const newTab = window.open(`/deals/create/${uuid}/`, "_blank", "noopener,noreferrer")
    if (newTab) newTab.opener = null
  }

  let classes = []
  if (isSelected) classes.push(styles.selectedRow)
  if (mostRecentDeal) classes.push(styles.expandable)

  return (
    <tr
      className={classes.join(" ")}
      {...(mostRecentDeal && !isSelected && { onClick: () => handleClick(result["id"]), role: "button" })}
    >
      <td>{result.position}.</td>
      <td>
        <span className="text-nowrap">{resultUrl}</span>
        {actualDeal && (
          <span className="ms-3 text-success">
            оплачено до <b>{formatDate(result["actual_deal"].end_date)}</b>
          </span>
        )}
        {isSelected && mostRecentDeal && (
          <div className="fw-normal">
            последняя сделка до {formatDate(mostRecentDeal.end_date)}| цена покупки: {mostRecentDeal.purchase_price} |
            цена продажи: {mostRecentDeal.selling_price}
            <button className="btn btn-primary ms-3" onClick={handleCopy}>
              Добавить
            </button>
          </div>
        )}
      </td>
    </tr>
  )
}

export default ResultRow
