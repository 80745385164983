import API from "services/api";

class ClientsService {
  static async fetchAll() {
    try {
      const res = await API.get("/clients/")
      return res.data
    } catch (error) {
      console.log(error)
    }

  }

  static async fetchById(clientId) {
    try {
      const res = await API.get(`/clients/${clientId}/`)
      return res.data
    } catch (error) {
      console.log(error)
    }
  }
  static async getStats(clientId) {
    try {
      const res = await API.get(`/clients/${clientId}/get_statistics/`)
      return res.data
    } catch(error) {
      console.log(error)
    }
  }
}

export default ClientsService
