import API from "services/api"

class SerpsService {
  static async fetchById(serpId) {
    try {
      const res = await API.get(`/serps/${serpId}/`)
      return res.data
    }
    catch (error) {
      console.log(error)
    }
  }
}

export default SerpsService
