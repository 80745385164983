import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import Loader from "components/Loader"
import { Container, Table } from "react-bootstrap"
import Breadcrumbs from "components/Breadcrumbs"
import { observer } from "mobx-react-lite"
import { formatDate } from "utils/datetime"
import { Helmet } from "react-helmet-async"
import SitesService from "services/SitesService"

const SiteDetailPage = () => {
  const [site, setSite] = useState(null)

  let { id: siteId } = useParams()
  siteId = parseInt(siteId)

  useEffect(() => {
    ;(async () => {
      const data = await SitesService.fetchById(siteId)
      setSite(data)
    })()
  }, [siteId])

  if (!site) {
    return <Loader />
  }

  return (
    <Container fluid>
      <Helmet>
        <title>{`Otzoff.ru | Сайты | ${site.name}`}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { to: "/", title: "Главная" },
          { to: "/sites/", title: "Сайты" },
          { to: null, title: site.name },
        ]}
      />
      <Table>
        <tbody>
          <tr>
            <td>id</td>
            <td>{site.id}</td>
          </tr>
          <tr>
            <td>Название</td>
            <td>
              <a href={site.url}>{site.name}</a>
            </td>
          </tr>
          <tr>
            <td>url</td>
            <td>
              <a href={site.url}>{site.url}</a>
            </td>
          </tr>
          <tr>
            <td>Создан</td>
            <td>{formatDate(site.created)}</td>
          </tr>
          <tr>
            <td>Изменён</td>
            <td>{formatDate(site.modified)}</td>
          </tr>
          <tr>
            <td>Email</td>
            <td>{!!site.email && <a href={`mailto:${site.email}`}>{site.email}</a>}</td>
          </tr>
          <tr>
            <td>Способ оплаты</td>
            <td>{site.payment_type}</td>
          </tr>
          <tr>
            <td>Комментарий</td>
            <td>{site.comment}</td>
          </tr>
        </tbody>
      </Table>
    </Container>
  )
}

export default observer(SiteDetailPage)
