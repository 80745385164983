import React, { useEffect, useState } from "react"
import {useParams} from "react-router-dom"
import { Container } from "react-bootstrap"
import { observer } from "mobx-react-lite"
import Loader from "components/Loader"
import ClientCommonInfo from "./ClientCommonInfo"
import ClientDealsInfo from "./ClientDealsInfo"
import Breadcrumbs from "components/Breadcrumbs"
import ClientKeyPhrasesInfo from "pages/clients/ClientDetailPage/ClientKeyPhrasesInfo"
import ClientsService from "services/ClientsService"
import { Helmet } from "react-helmet-async"

const ClientDetailPage = observer(() => {
  const [client, setClient] = useState(null)

  let { id: clientId } = useParams()
  clientId = parseInt(clientId)

  useEffect(() => {
    ;(async () => {
      const res = await ClientsService.fetchById(clientId)
      setClient(res)
    })()
  }, [clientId])

  if (!client) {
    return <Loader />
  }

  return (
    <Container fluid>
      <Helmet>
        <title>{`Otzoff.ru | Клиенты | ${client.name}`}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { to: "/", title: "Главная" },
          { to: "/clients/", title: "Клиенты" },
          { to: null, title: client.name },
        ]}
      />
      <h1 className="page-title">{client.name}</h1>
      <ClientCommonInfo client={client} />
      <ClientKeyPhrasesInfo client={client} />
      <ClientDealsInfo client={client} />
    </Container>
  )
})
export default ClientDetailPage
