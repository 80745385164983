import React from "react";
import {Card, Table} from "react-bootstrap";
import {observer} from "mobx-react-lite";

import {formatDate} from "utils/datetime";

const ClientCommonInfo = ({client}) => (
  <Card>
    <Card.Header><b>Основная информация</b></Card.Header>
    <Card.Body>
      <Table size="sm">
        <tbody>
        <tr>
          <td>Дата добавления</td>
          <td>{formatDate(client.created)}</td>
        </tr>
        <tr>
          <td>Последнее изменение</td>
          <td>{formatDate(client.modified)}</td>
        </tr>
        <tr>
          <td>Контакт</td>
          <td>{client.contact}</td>
        </tr>
        <tr>
          <td>Телефон</td>
          <td>
            {!!client.phone && <a href={`tel:${client.phone}`}>{client.phone}</a>}
          </td>
        </tr>
        <tr>
          <td>email</td>
          <td>
            {!!client.email && <a href={`mailto:${client.email}`}>{client.email}</a>}
          </td>
        </tr>
        </tbody>
      </Table>
    </Card.Body>
  </Card>
)

export default observer(ClientCommonInfo)
