import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import KeyPhrasesService from "services/KeyPhrasesService"
import Loader from "components/Loader"
import { Helmet } from "react-helmet-async"
import Breadcrumbs from "components/Breadcrumbs"
import { Container } from "react-bootstrap"
import CollapsableCard from "components/CollapsableCard"
import { formatDateTime } from "utils/datetime"
import Serp from "components/serp/Serp"
import { SeHighlight } from "utils/highlight"

const KeyPhraseDetailPage = () => {
  const [keyphrase, setKeyphrase] = useState(null)

  let { id: keyPhraseId } = useParams()
  keyPhraseId = parseInt(keyPhraseId)

  useEffect(() => {
    ;(async () => {
      const res = await KeyPhrasesService.fetchById(keyPhraseId)
      setKeyphrase(res)
    })()
  }, [keyPhraseId])
  if (!keyphrase) return <Loader />

  return (
    <Container fluid>
      <Helmet>
        <title>{`Otzoff.ru | Клиенты | ${keyphrase.client.name} | ${keyphrase.text}`}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { to: "/", title: "Главная" },
          { to: "/clients/", title: "Клиенты" },
          { to: `/clients/${keyphrase.client.id}/`, title: keyphrase.client.name },
          { to: null, title: `"${keyphrase.text}"` },
        ]}
      />
      {keyphrase.search_engines.map((se) => (
        <CollapsableCard
          key={se.id}
          title={
            <div>
              <span className="fw-bold">
                <SeHighlight>{se.name}</SeHighlight>
              </span>
              <span className="ms-2 text-secondary">({formatDateTime(se.last_check)})</span>
            </div>
          }
          className="mt-3"
        >
          <Serp serpId={se.serps[0]} />
        </CollapsableCard>
      ))}
    </Container>
  )
}

export default KeyPhraseDetailPage
