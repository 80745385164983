import API from "services/api"

class AuthService {
  static async login(username, password) {
    try {
      const res = await API.post(process.env.REACT_APP_API_AUTH_URL,
        {username, password},
        )
      return res.data
    }
    catch (error) {
      console.log(error)
      throw Error("не верные логин и/или пароль")
    }
  }
  static async getUserInfo() {
    try {
      const res = await API.get("/auth/userinfo/")
      return res.data
    }
    catch (error) {
      console.log(error)
      throw error
    }
  }
}

export default AuthService
