export const formatDate = (dateString) => {
  const dt = new Date(dateString)
  return dt.toLocaleDateString()
}

export const formatDateTime = datetimeString => {
  const dt = new Date(datetimeString)
  return dt.toLocaleString([], {
    dateStyle: "short",
    timeStyle: "short"
  })
}
