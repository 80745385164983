import React, { useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { Button, Container, Modal } from "react-bootstrap"
import Breadcrumbs from "components/Breadcrumbs"
import { observer } from "mobx-react-lite"
import Loader from "components/Loader"
import DealsService from "services/DealsService"
import DealInfo from "pages/deals/DealDetailPage/DealInfo"
import { Helmet } from "react-helmet-async"
import NotificationManager from "utils/notifications"

const DealDetailPage = () => {
  const history = useHistory()
  const [deal, setDeal] = useState()
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showCopyModal, setShowCopyModal] = useState(false)

  let { id: dealId } = useParams()
  dealId = parseInt(dealId)

  useEffect(() => {
    async function fetchDeal(dealId) {
      try {
        return await DealsService.fetchById(dealId)
      } catch (error) {
        if (error?.response.status === 404) {
          NotificationManager.warning("страница не найдена")
          history.push("/deals/")
        }
      }
    }

    fetchDeal(dealId).then((data) => setDeal(data))
  }, [dealId, history])

  const deleteDeal = async () => {
    await DealsService.delete(deal["id"])
    setShowDeleteModal(false)
    NotificationManager.success("сделка удалена")
    history.push("/deals/")
    history.replace("/deals/")
  }

  const copyDeal = () =>
    history.push("/deals/create/", {
      initial: {
        start_date: deal["start_date"],
        end_date: deal["end_date"],
        client: deal["client"]["id"],
        site: deal["site"]["id"],
        url: deal["url"],
        selling_price: deal["selling_price"],
        purchase_price: deal["purchase_price"],
      },
    })

  if (!deal) {
    return <Loader />
  }

  return (
    <Container fluid>
      <Helmet>
        <title>{`Otzoff.ru | Сделки | ${deal.id}`}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { to: "/", title: "Главная" },
          { to: "/deals/", title: "Сделки" },
          { to: null, title: `#${deal.id}` },
        ]}
      />
      <div className="text-end">
        <Link to={`/deals/${deal.id}/edit/`} className="btn btn-primary btn-sm">
          Редактировать
        </Link>
        <Button variant="info" size="sm" onClick={() => setShowCopyModal(true)} className="ms-1">
          Копировать
        </Button>
        <Button variant="danger" size="sm" onClick={() => setShowDeleteModal(true)} className="ms-1">
          Удалить
        </Button>
      </div>
      <DealInfo deal={deal} />

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Body>Удалить сделку?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Отмена
          </Button>
          <Button variant="danger" onClick={deleteDeal}>
            Удалить
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCopyModal} onHide={() => setShowCopyModal(false)}>
        <Modal.Body>Копировать сделку?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCopyModal(false)}>
            Отмена
          </Button>
          <Button variant="info" onClick={copyDeal}>
            Копировать
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  )
}

export default observer(DealDetailPage)
