import React, { useCallback, useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Container } from "react-bootstrap"
import Breadcrumbs from "components/Breadcrumbs"
import DealTable from "components/DealTable"
import DealsService from "services/DealsService"
import { NumberParam, useQueryParams } from "use-query-params"
import { Helmet } from "react-helmet-async"
import { Link } from "react-router-dom"
import DealFilter from "components/DealFilter"

const DealListPage = () => {
  const [deals, setDeals] = useState(null)
  const [filters, setFilters] = useState(undefined)
  const [isLoading, setIsLoading] = useState(true)

  const [query, setQuery] = useQueryParams({
    page: NumberParam,
    pageSize: NumberParam,
  })

  const fetchDeals = useCallback(() => {
    ;(async function () {
      setIsLoading(true)
      return await DealsService.fetchList({ ...query, ...filters })
    })()
      .then((data) => setDeals(data))
      .then(() => setIsLoading(false))
  }, [query, filters])

  useEffect(() => {
    if (filters !== undefined) {
      fetchDeals()
    }
  }, [fetchDeals, filters])

  const handlePageChange = useCallback(
    (page) => {
      setQuery({ page: page === 1 ? undefined : page })
    },
    [setQuery]
  )

  const handleFilterChange = useCallback(
    (data) => {
      setFilters(data)
      setQuery({ page: undefined })
    },
    [setFilters, setQuery]
  )

  const handleDelete = useCallback(() => fetchDeals(), [fetchDeals])

  return (
    <Container fluid>
      <Helmet>
        <title>Otzoff.ru | Сделки</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { to: "/", title: "Главная" },
          { to: null, title: "Сделки" },
        ]}
      />
      <div className="text-end my-2">
        <Link to="/deals/create/" className="btn btn-primary btn-sm">
          Добавить сделку
        </Link>
      </div>

      <DealFilter onChange={handleFilterChange} />
      <DealTable
        deals={deals?.results}
        striped
        isLoading={isLoading}
        pagination
        paginationPerPage={query.pageSize || 50}
        paginationDefaultPage={query.page}
        paginationServer
        paginationTotalRows={deals?.count}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={(pageSize) => setQuery({ pageSize })}
        onDelete={() => handleDelete()}
      />
    </Container>
  )
}

export default observer(DealListPage)
