import React, { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Container } from "react-bootstrap"
import NotificationManager from "utils/notifications"
import Breadcrumbs from "components/Breadcrumbs"
import { observer } from "mobx-react-lite"
import Loader from "components/Loader"
import DealsService from "services/DealsService"
import DealForm from "components/DealForm"
import { Helmet } from "react-helmet-async"

const DealEditPage = () => {
  const history = useHistory()
  const [deal, setDeal] = useState()
  const [errorsDict, setErrorsDict] = useState(null)

  let { id: dealId } = useParams()
  dealId = parseInt(dealId)

  useEffect(() => {
    async function fetchDeal(dealId) {
      return await DealsService.fetchById(dealId)
    }
    fetchDeal(dealId).then((data) => setDeal(data))
  }, [dealId])

  const onSubmit = async (data) => {
    try {
      Object.keys(data).forEach((key) => {
        if (data[key] === "") {
          data[key] = null
        }
      })
      await DealsService.update(data)
      NotificationManager.success("сделка сохранена")
      history.push(`/deals/${deal.id}/`)
    } catch (error) {
      NotificationManager.warning("Ошибка сохранения формы")
      setErrorsDict(error)
    }
  }

  if (!deal) {
    return <Loader />
  }

  return (
    <Container fluid>
      <Helmet>
        <title>{`Otzoff.ru | Сделки | ${deal.id} | редактирование`}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { to: "/", title: "Главная" },
          { to: "/deals/", title: "Сделки" },
          { to: `/deals/${deal.id}/`, title: `Сделка #${deal.id}` },
          { to: null, title: `редактирование` },
        ]}
      />
      <DealForm
        deal={deal}
        initial={{
          id: deal["id"],
          client: deal["client"]["id"],
          site: deal["site"]["id"],
          url: deal["url"],
          start_date: deal["start_date"],
          end_date: deal["end_date"],
          selling_price: deal["selling_price"],
          purchase_price: deal["purchase_price"],
        }}
        errorsDict={errorsDict}
        onSubmit={onSubmit}
      />
    </Container>
  )
}

export default observer(DealEditPage)
