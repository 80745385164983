import React from 'react';
import {Link} from "react-router-dom"
import {formatDate} from "utils/datetime"
import {Table} from "react-bootstrap"
import punydecode from "utils/punidecode"

const DealInfo = ({deal}) => {
  return (
    <Table responsive>
      <tbody>
      <tr>
        <td>id</td>
        <td>{deal.id}</td>
      </tr>
      <tr>
        <td>Клиент</td>
        <td><Link to={`/clients/${deal.client.id}/`}>{deal.client.name}</Link></td>
      </tr>
      <tr>
        <td>Сайт</td>
        <td><Link to={`/sites/${deal.site.id}/`}>{deal.site.name}</Link></td>
      </tr>
      <tr>
        <td>Url</td>
        <td><a href={deal.url}>{punydecode(deal.url)}</a></td>
      </tr>
      <tr>
        <td>Создана</td>
        <td>{formatDate(deal.created)}</td>
      </tr>
      <tr>
        <td>Последнее изменение</td>
        <td>{formatDate(deal.modified)}</td>
      </tr>
      <tr>
        <td>Цена покупки</td>
        <td>{deal.purchase_price}</td>
      </tr>
      <tr>
        <td>Цена продажи</td>
        <td>{deal.selling_price}</td>
      </tr>
      <tr>
        <td>Дата начала</td>
        <td>{deal.start_date}</td>
      </tr>
      <tr>
        <td>Дата окончания</td>
        <td>{deal.end_date}</td>
      </tr>
      <tr>
        <td>Статус</td>
        <td className={deal.status === "оплачено" ? "text-success" : "text-warning"}>{deal.status}</td>
      </tr>
      </tbody>
    </Table>
  );
};

export default DealInfo;
