import React, {useContext} from 'react';
import {Alert, Button, Container, Form, FormControl, FormGroup} from "react-bootstrap"
import {Controller, useForm} from "react-hook-form"
import {StoreContext} from "store"
import {observer} from "mobx-react-lite"
import {useHistory} from "react-router-dom"

const LoginPage = () => {
  const {authStore} = useContext(StoreContext)
  const history = useHistory()
  const {handleSubmit, control} = useForm({mode: "onBlur"})

  const onSubmit = data => {
    authStore.login(data.username, data.password).then(() => history.push("/deals/"))
  }

  return (
    <Container>
      <Form onSubmit={handleSubmit(onSubmit)} style={{maxWidth: 500, margin: "5rem auto"}}>
        {!!authStore.error &&
        (
          <Alert variant="danger">{authStore.error}</Alert>
        )}
        <FormGroup>
          <Form.Label>Логин</Form.Label>
          <Controller
            name="username"
            defaultValue=""
            control={control}
            rules={{required: "обязательное поле"}}
            render={({field, fieldState: {invalid, error}}) => (
              <>
                <Form.Control {...field} isInvalid={invalid}/>
                {!!error && (
                  <FormControl.Feedback type="invalid">
                    {error.message}
                  </FormControl.Feedback>
                )}
              </>
            )}
          />
        </FormGroup>
        <FormGroup>
          <Form.Label>Пароль</Form.Label>
          <Controller
            name="password"
            defaultValue=""
            rules={{required: "обязательное поле"}}
            control={control}
            render={({field, fieldState: {invalid, error}}) => (
              <>
                <Form.Control {...field} isInvalid={invalid} type="password"/>
                {!!error && (
                  <FormControl.Feedback type="invalid">
                    {error.message}
                  </FormControl.Feedback>
                )}
              </>
            )}
          />
        </FormGroup>
        <FormGroup className="mt-3 d-grid">
          <Button type="submit">Submit</Button>
        </FormGroup>
      </Form>
    </Container>
  );
};

export default observer(LoginPage);
