import React from "react";
import DataTable from "react-data-table-component"
import {observer} from "mobx-react-lite";
import {Link} from "react-router-dom";
import Loader from "components/Loader"

const ClientsTable = ({clients, isLoading, ...props}) => {
  let columns = [
    {
      name: "#",
      selector: client => client.id,
      format: client => <Link to={`/clients/${client.id}/`}>{client.id}</Link>,
      sortable: true,
      minWidth: "77px",
      grow: 0
    },
    {
      name: "Название",
      selector: client => client.name,
      format: client => <Link to={`/clients/${client.id}/`}>{client.name}</Link>,
      sortable: true
    },
    {
      name: "Контакт",
      selector: client => client.contact,
      sortable: true
    },
    {
      name: "Email",
      selector: client => client.email,
      sortable: true
    },
    {
      name: "Телефон",
      selector: client => client.phone,
      sortable: true
    }
  ]

  return <DataTable
    columns={columns}
    striped
    data={clients}
    dense={true}
    defaultSortFieldId={1}
    defaultSortAsc={false}
    progressPending={isLoading}
    progressComponent={Loader()}
  />
}

export default observer(ClientsTable);
