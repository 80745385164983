import {useEffect, useState} from "react"
import ClientsService from "services/ClientsService"
import {useParams} from "react-router-dom"

function useClients(initial = null) {
  const [clients, setClients] = useState(initial);

  useEffect(() => {
    async function loadClients() {
      return await ClientsService.fetchAll()
    }

    loadClients().then(data => setClients(data))
  }, []);
  return clients
}

const useClient = (initial = null) => {
  const [client, setClient] = useState(initial)
  let {id: clientId} = useParams()
  clientId = parseInt(clientId)
  useEffect(() => (async () => {
      const res = await ClientsService.fetchById(clientId)
      setClient(res)
    })(), [clientId]
  )
  return client
}

export {useClient, useClients}
