import {makeAutoObservable} from "mobx"
import AuthService from "services/AuthService"

class Auth {
  user = {}
  token = null
  isAuthenticated = false
  isLoaded = false
  error = null

  constructor() {
    this.token = localStorage.getItem("token") || null
    makeAutoObservable(this, {}, {autoBind: true})
  }


  * login(username, password) {
    try {
      this.error = null
      const {token} = yield AuthService.login(username, password)
      this.token = token
      localStorage.setItem("token", token)
    } catch (error) {
      this.error = error.message
    }
    this.isLoaded = true
  }

  logout() {
    this.user = {}
    this.token = null
    this.isAuthenticated = false
    localStorage.removeItem("token")
  }

  * getUserInfo() {
    try {
      this.user = yield AuthService.getUserInfo()
      this.isLoaded = true
      this.isAuthenticated = true
    }
    catch (error) {
      if (error.response && error.response.status === 401) {
        this.logout()
      }
      else {
        this.error = "ошибка авторизации"
        this.isLoaded = true
      }
    }
  }
}

export default Auth
