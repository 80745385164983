import React, {useEffect, useState} from 'react'
import {FormProvider, useForm} from "react-hook-form"
import {Button, Col, Form, Row} from "react-bootstrap"
import ClientsService from "services/ClientsService"
import SitesService from "services/SitesService"
import FormInput from "components/forms/FormInput"
import FormSelect from "components/forms/FormSelect"

const DealForm = ({onSubmit, initial, errorsDict}) => {
  const methods = useForm({
    defaultValues: {
      id: initial["id"],
      url: initial["url"] || "",
      site: initial["site"] || null,
      client: initial["client"] || null,
      "start_date": initial["start_date"] || null,
      "end_date": initial["end_date"] || null,
      "purchase_price": initial["purchase_price"] || null,
      "selling_price": initial["selling_price"] || null,
    }
  })
  const {handleSubmit, setError, register, formState} = methods
  const [clients, setClients] = useState([])
  const [sites, setSites] = useState([])

  useEffect(() => {
    if (errorsDict) {
      for (const [name, errors] of Object.entries(errorsDict)) {
        errors.forEach(error => setError(name, {type: "invalid", message: error}))
      }

    }
  }, [errorsDict, setError])

  useEffect(() => {
    (async () => {
      const res = await ClientsService.fetchAll()
      setClients(res)
    })()
  }, []);
  useEffect(() => {
    (async () => {
      const res = await SitesService.fetchAll()
      setSites(res)
    })()
  }, [])

  const clientOptions = clients.map(client => ({value: client.id, label: client.name}))
  const sitesOptions = sites.map(site => ({value: site.id, label: site.name}))

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" {...register("id")} />
        <FormSelect label="Клиент" name="client" options={clientOptions}/>
        <FormSelect label="Сайт" name="site" options={sitesOptions}/>
        <FormInput label="Url" name="url" type="url" formState={formState} register={register}/>
        <FormInput label="Дата начала" name="start_date" type="date"/>
        <FormInput label="Дата окончания" name="end_date" type="date"/>
        <FormInput label="Цена покупки" name="purchase_price" type="number"/>
        <FormInput label="Цена продажи" name="selling_price" type="number"/>
        <Row>
          <Col sm={{span: 10, offset: 2}}>
            <Button type="submit" className="mt-3 px-5">Сохранить</Button>
          </Col>
        </Row>
      </Form>
    </FormProvider>
  );
};

export default DealForm;
