import API from "services/api"

class DealsService {
  static async fetchList(params) {
    params = params || {}
    try {
      const res = await API.get("/deals/", { params })
      return res.data
    } catch (error) {
      console.log(error)
    }
  }
  static async fetchById(dealId) {
    try {
      const res = await API.get(`/deals/${dealId}`)
      return res.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  static async update(data) {
    try {
      return await API.put(`/deals/${data.id}/`, data)
    } catch (error) {
      if (error.response.status === 400) {
        throw error.response.data
      } else {
        console.log(error)
      }
    }
  }
  static async create(data) {
    try {
      return await API.post(`/deals/`, data)
    } catch (error) {
      if (error.response.status === 400) {
        throw error.response.data
      } else {
        console.log(error)
      }
    }
  }
  static async delete(id) {
    try {
      return await API.delete(`/deals/${id}/`)
    } catch (error) {
      console.log(error)
      throw error
    }
  }
  static async fetchByClient(clientId) {
    try {
      const res = await API.get("/deals/", { params: { client: clientId } })
      return res.data
    } catch (error) {
      console.log(error)
    }
  }
  static async putTempDeal(data) {
    try {
      const res = await API.post("/deals/copy/", data)
      return res.data.uuid
    } catch (error) {
      console.log(error)
    }
  }
  static async fetchTempDeal(uuid) {
    try {
      const res = await API.get(`/deals/copy/${uuid}/`)
      return res.data
    } catch (error) {
      console.log(error)
    }
  }
}

export default DealsService
