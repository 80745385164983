import API from "services/api"

class KeyPhrasesService {
  static async fetchByClient(clientId) {
    try {
      const res = await API.get(`/keyphrases/?client=${clientId}`)
      return res.data
    } catch (error) {
      console.log(error)
    }
  }
  static async fetchById(keyPhraseId) {
    try {
      const res = await API.get(`keyphrases/${keyPhraseId}/`)
      return res.data
    }
    catch (error) {
      console.log(error)
    }
  }
}

export default KeyPhrasesService
