import React, {useEffect, useState} from 'react';
import {Table} from "react-bootstrap"
import KeyPhrasesService from "services/KeyPhrasesService"
import CollapsableCard from "components/CollapsableCard"
import {Link} from "react-router-dom"
import {formatDateTime} from "utils/datetime"

const ClientKeyPhrasesInfo = ({client}) => {
  const [keyphrases, setKeyphrases] = useState([]);

  useEffect(() => {
    (async () => {
      const res = await KeyPhrasesService.fetchByClient(client.id)
      setKeyphrases(res)
    })()
  }, [client.id]);

  return (
    <CollapsableCard title={<b>Ключевые слова</b>} isLoading={!keyphrases} className="mt-3">
      <Table size="sm" className="mt-3">
        <tbody>
        {keyphrases.map(keyphrase => (
          <tr key={keyphrase.id}>
            <td>
              <Link to={`/keyphrases/${keyphrase.id}/`}>
                {keyphrase.text}
              </Link>
            </td>
            <td>
              <Table size="sm">
                <tbody>
                {keyphrase.search_engines.map(se => (
                  <tr key={se.id}>
                    <td>{se.name}</td>
                    <td>{se.last_check ? <i> ({formatDateTime(se.last_check)})</i> : null}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </CollapsableCard>
  );
};

export default ClientKeyPhrasesInfo;
