import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { Container } from "react-bootstrap"
import Breadcrumbs from "components/Breadcrumbs"
import SitesTable from "components/SitesTable"
import SitesService from "services/SitesService"
import { Helmet } from "react-helmet-async"

const SiteListPage = () => {
  const [sites, setSites] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    async function fetchSites() {
      setIsLoading(true)
      const data = await SitesService.fetchAll()
      setSites(data)
      setIsLoading(false)
    }
    fetchSites()
  }, [])

  return (
    <Container fluid>
      <Helmet>
        <title>{`Otzoff.ru | Сайты`}</title>
      </Helmet>
      <Breadcrumbs
        items={[
          { to: "/", title: "Главная" },
          { to: null, title: "Сайты" },
        ]}
      />
      <SitesTable sites={sites} isLoading={isLoading} />
    </Container>
  )
}

export default observer(SiteListPage)
