import React, {useEffect, useState} from "react";
import {observer} from "mobx-react-lite"

import DealTable from "components/DealTable";
import DealsService from "services/DealsService"
import CollapsableCard from "components/CollapsableCard"
import {Link} from "react-router-dom"

const ClientDealsInfo = ({client}) => {
  const [deals, setDeals] = useState(null);

  useEffect(() => {
    (async () => {
      const res = await DealsService.fetchByClient(client.id)
      setDeals(res)
    })()
  }, [client.id]);


  return (
    <CollapsableCard title={<b>Сделки</b>} className="mt-3" isLoading={!deals}>
      {!!deals?.results.length ?
        <>
          <DealTable deals={deals.results.slice(0, 10)} exclude={["Клиент"]}/>
          <div className="text-end text-secondary pe-3">
            Всего {deals.count}
          </div>
        </>
        : <div className="text-center text-secondary">Сделок пока нет</div>
      }
      <div>
        <Link to={`/clients/${client.id}/stats/`}>Статистика</Link>
      </div>
    </CollapsableCard>
  );
}

export default observer(ClientDealsInfo)
