import React from 'react';
import {Form} from "react-bootstrap"

const RadioGroup = ({name, value, onChange, options}) => {
  return (
    <>
      {options.map(option => (
        <Form.Check
          key={option.label}
          id={`id-${name}-${option.value}`}
          type="radio"
          name={name}
          checked={value === option.value}
          value={option.value}
          label={option.label}
          onChange={e => onChange(option.value)}
        />
      ))}
    </>
  );
};

export default RadioGroup;
