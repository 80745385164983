import API from "services/api"


class SitesService {
  static async fetchAll() {
    try{
      const res = await API.get("/sites/")
      return res.data
    }
    catch (error) {
      console.log(error)
    }
  }
  static async fetchById(siteId) {
    try {
      const res = await API.get(`/sites/${siteId}/`)
      return res.data
    }
    catch (error) {
      console.log(error)
    }
  }
}

export default SitesService
