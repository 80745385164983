import ClientListPage from "./pages/clients/ClientListPage"
import ClientDetailPage from "./pages/clients/ClientDetailPage"
import Page404 from "./pages/Page404"
import { Redirect } from "react-router-dom"
import DealListPage from "pages/deals/DealListPage"
import DealDetailPage from "pages/deals/DealDetailPage"
import LoginPage from "pages/auth/login"
import SiteListPage from "pages/sites/SiteListPage"
import SiteDetailPage from "pages/sites/SiteDetailPage"
import DealEditPage from "pages/deals/DealEditPage"
import KeyPhraseDetailPage from "pages/keyphrases/KeyPhraseDetailPage"
import DealCreatePage from "pages/deals/DealCreatePage"
import ClientStatsPage from "pages/clients/ClientStatsPage"

export const protectedRoutes = [
  {
    path: "/clients/",
    component: ClientListPage,
    exact: true,
  },
  {
    path: "/clients/:id/",
    component: ClientDetailPage,
    exact: true,
  },
  {
    path: "/clients/:id/stats/",
    component: ClientStatsPage,
    exact: true,
  },
  {
    path: "/keyphrases/:id/",
    component: KeyPhraseDetailPage,
    exact: true,
  },
  {
    path: "/deals/",
    component: DealListPage,
    exact: true,
  },
  {
    path: "/deals/create/",
    component: DealCreatePage,
    exact: true,
  },
  {
    path: "/deals/create/:uuid/",
    component: DealCreatePage,
    exact: true,
  },
  {
    path: "/deals/:id/",
    component: DealDetailPage,
    exact: true,
  },
  {
    path: "/deals/:id/edit/",
    component: DealEditPage,
    exact: true,
  },
  {
    path: "/sites/",
    component: SiteListPage,
    exact: true,
  },
  {
    path: "/sites/:id/",
    component: SiteDetailPage,
    exact: true,
  },
  {
    path: "/",
    component: () => <Redirect to="/deals/" />,
    exact: true,
  },
  {
    path: "/auth/login/",
    component: () => <Redirect to="/" />,
  },
  {
    path: "*",
    component: Page404,
    exact: false,
  },
]

export const publicRoutes = [
  {
    path: "/auth/login/",
    component: LoginPage,
  },
  {
    path: "*",
    component: () => <Redirect to="/auth/login/" />,
    exact: false,
  },
]
