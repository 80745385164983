import React, { useEffect, useMemo, useState } from "react"
import { NumberParam, StringParam, useQueryParams } from "use-query-params"
import Select from "react-select"
import debounce from "lodash.debounce"
import {useClients} from "hooks/data/clients"
import useSites from "hooks/data/sites"
import RadioGroup from "components/DealFilter/RadioGroup"
import { CloseButton, Form, InputGroup } from "react-bootstrap"

const DealFilter = ({ onChange }) => {
  const [query, setQuery] = useQueryParams({
    client: NumberParam,
    site: NumberParam,
    status: StringParam,
    actual: StringParam,
    search: StringParam,
  })

  const clients = useClients([])
  const clientsOptions = clients
    .map((client) => ({
      value: client.id,
      label: client.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))

  const sites = useSites([])
  const sitesOptions = sites
    .map((site) => ({
      value: site.id,
      label: site.name,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
  const { client: selectedClientId, site: selectedSiteId } = query

  const debouncedSearchCallback = useMemo(
    () => debounce((value) => setQuery({ search: value ? value : undefined }), 500),
    [setQuery]
  )
  const [searchInputValue, setSearchInputValue] = useState(query.search)

  const handleSearch = (value) => {
    setSearchInputValue(value)
    debouncedSearchCallback(value)
  }

  useEffect(() => {
    onChange(query)
  }, [query, onChange])

  return (
    <div className="py-3">
      <div className="row">
        <div className="order-lg-4 col-6 col-md-6 col-lg-2">
          <RadioGroup
            onChange={(value) => setQuery({ status: value })}
            options={[
              { label: "все", value: undefined },
              {
                label: "оплачено",
                value: "paid",
              },
              { label: "не оплачено", value: "not_paid" },
            ]}
            value={query.status}
            name="status"
          />
        </div>
        <div className="order-lg-5 col-6 col-md-6 col-lg-2">
          <RadioGroup
            onChange={(value) => setQuery({ actual: value })}
            options={[
              { label: "все", value: undefined },
              { label: "актуальные", value: "true" },
              { label: "не актуальные", value: "false" },
            ]}
            value={query.actual}
            name="actual"
          />
        </div>
        <div className="order-md-1 col-sm-6 col-md-4 col-lg-2 py-2">
          <Select
            placeholder="клиент"
            options={clientsOptions}
            value={clientsOptions.find((option) => option.value === selectedClientId) || null}
            isClearable={true}
            onChange={(option) => setQuery({ client: option?.value })}
          />
        </div>
        <div className="order-md-2 col-sm-6 col-md-4 col-lg-2 py-2">
          <Select
            placeholder="сайт"
            options={sitesOptions}
            value={sitesOptions.find((option) => option.value === selectedSiteId) || null}
            isClearable={true}
            onChange={(option) => setQuery({ site: option?.value })}
          />
        </div>
        <div className="order-md-0 col-md-4 col-lg-4 py-2">
          <InputGroup>
            <Form.Control placeholder="поиск" value={searchInputValue} onChange={(e) => handleSearch(e.target.value)} />
            <InputGroup.Text>
              <CloseButton style={{ fontSize: "0.5rem" }} onClick={() => handleSearch("")} />
            </InputGroup.Text>
          </InputGroup>
        </div>
      </div>
    </div>
  )
}

export default DealFilter
