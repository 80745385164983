import {store} from "react-notifications-component"


class NotificationManager {
  default = {
    container: "top-right",
    slidingExit: {
      duration: 200
    },
    dismiss: {
      duration: 2500,
      onScreen: true,
      showIcon: true
    },
    animationIn: ["animate__animated animate__fadeIn"]
  }
  success(message) {
    store.addNotification({
      ...this.default,
      type: "success",
      message
    })
  }
  info(message) {
    store.addNotification({
      ...this.default,
      type: "info",
      message
    })
  }
  warning(message) {
    store.addNotification({
      ...this.default,
      type: "warning",
      message
    })
  }
  danger(message) {
    store.addNotification({
      ...this.default,
      type: "danger",
      message
    })
  }
}

export default new NotificationManager()
