import React, { useState } from "react"
import { Card, Collapse } from "react-bootstrap"
import Loader from "components/Loader"

const CollapsableCard = ({ title, children, isLoading, ...props }) => {
  const [isOpen, setIsOpen] = useState(true)

  return (
    <Card {...props}>
      <Card.Header className="d-flex" onClick={() => setIsOpen(!isOpen)} role="button">
        {title}
        <span className="ms-auto font-monospace">{isOpen ? "свернуть" : "показать"}</span>
      </Card.Header>
      <Collapse in={isOpen} style={{ transition: "height 0.3s ease" }}>
        <Card.Body>{isLoading ? <Loader /> : children}</Card.Body>
      </Collapse>
    </Card>
  )
}

export default CollapsableCard
