import React, {useContext, useEffect} from "react"
import {QueryParamProvider} from "use-query-params"
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom"
import {observer} from "mobx-react-lite"

import Header from "components/Header"
import NotificationManager from "utils/notifications"
import {protectedRoutes, publicRoutes} from "routes"
import {StoreContext} from "store"
import Loader from "components/Loader"
import {HelmetProvider} from "react-helmet-async"


function App() {

  const {authStore} = useContext(StoreContext)

  useEffect(() => {
    if (authStore.token) {
      authStore.getUserInfo()
    }
  }, [authStore, authStore.token]);

  useEffect(() => {
    if (authStore.error) {
      NotificationManager.danger(authStore.error)
    }
  }, [authStore.error])

  if (authStore.token && !authStore.isLoaded) {
    return <Loader/>
  }

  const routes = authStore.isAuthenticated ? protectedRoutes : publicRoutes


  return (
    <Router>
      <HelmetProvider>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Header/>
          <Switch>
            {routes.map(route => (
              <Route path={route.path} key={route.path} exact={route.exact}>
                <route.component/>
              </Route>
            ))}
            <Redirect to="/"/>
          </Switch>
        </QueryParamProvider>
      </HelmetProvider>
    </Router>
  );
}

export default observer(App)
