import React, {useEffect, useState} from "react"
import {Container, Table} from "react-bootstrap"
import {observer} from "mobx-react-lite"
import Breadcrumbs from "components/Breadcrumbs"
import {useClient} from "hooks/data/clients"
import {Helmet} from "react-helmet-async"
import Loader from "components/Loader"
import ClientsService from "services/ClientsService"
import {formatDate} from "utils/datetime"

const ClientStatsPage = observer(() => {
  const client = useClient()
  const [stats, setStats] = useState(null)
  useEffect(() => {
    async function getStats() {
      const res = await ClientsService.getStats(client.id)
      return res
    }

    if (client && stats === null) {
      getStats().then(res => setStats(res))
    }
  }, [client, stats])

  if (!client || !stats) {
    return <Loader/>
  }

  return (<Container fluid>
    <Helmet>
      <title>{`${client.name} | статистика | Otzoff.ru `}</title>
    </Helmet>
    <Breadcrumbs
      items={[{to: "/", title: "Главная"}, {to: "/clients/", title: "Клиенты"}, {
        to: `/clients/${client.id}/`,
        title: client.name
      }, {to: null, title: "Статистика"}]}
    />
    <h1 className="page-title">{client.name} статистика</h1>
    <Container fluid={true}>
      <Table size="sm" striped={true}>
        <thead>
        <tr>
          <td>Период</td>
          <td>Покупка</td>
          <td>Продажа</td>
          <td>Доход</td>
        </tr>
        </thead>
        <tbody>
        {stats.period_data.map(item => (
          <tr key={item.period}>
            <td>
              {formatDate(item.period)}
            </td>
            <td>{item.purchase.toLocaleString("en")}</td>
            <td>{item.selling.toLocaleString("en")}</td>
            <td>{item.profit.toLocaleString("en")}</td>
          </tr>
        ))}
        <tr className="fw-bold text-end">
          <td>Всего</td>
          <td>{stats.summary_data.purchase.toLocaleString("en")}</td>
          <td>{stats.summary_data.selling.toLocaleString("en")}</td>
          <td>{(stats.summary_data.selling - stats.summary_data.purchase).toLocaleString("en")}</td>
        </tr>
        </tbody>
      </Table>
    </Container>
  </Container>)
})

export default ClientStatsPage
